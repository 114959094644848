import React from 'react'
import { Link } from 'react-router-dom'
import './Card.css'

interface CardProps {
  id: string
  title: string
  description: string
  username: string
  likes: number
  onLike: () => void
  onFavorite: () => void
  isFavorite?: boolean
}

const Card: React.FC<CardProps> = ({
  id,
  title,
  description,
  username,
  likes,
  onLike,
  onFavorite,
  isFavorite = false,
}) => {
  return (
    <div className="card">
      <Link to={`/build/${id}`} className="card-link">
        <div className="card-image-container">
          <img
            src="https://via.placeholder.com/200x150?text=Computer"
            alt="Computer"
            className="card-image"
          />
        </div>
        <h3 className="card-title">{title}</h3>
        <p className="card-description">{description}</p>
      </Link>
      <div className="card-footer">
        <span className="card-username">{username}</span>
        <div className="card-actions">
          <button className="like-button" onClick={onLike}>
            ❤️ <span className="likes-count">{likes}</span>
          </button>
          <button className="favorite-button" onClick={onFavorite}>
            {isFavorite ? '⭐' : '☆'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Card
