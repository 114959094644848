import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'
import './App.css'
import Header from './components/Header'
import BuildDetails from './components/BuildDetails'
import UserProfile from './components/UserProfile'
import Feed from './components/Feed'
import LoginForm from './components/LoginForm'

interface User {
  id: string
  username: string
  avatar: string
}

interface Rig {
  id: string
  title: string
  description: string
  username: string
  likes: number
  components: { name: string; price: number; purchaseLink: string }[]
}

const MainApp: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [allRigs, setAllRigs] = useState<Rig[]>([])
  const [userRigs, setUserRigs] = useState<Rig[]>([])
  const [favoriteRigs, setFavoriteRigs] = useState<Rig[]>([])

  useEffect(() => {
    // Fetch all rigs, user rigs, and favorite rigs here
    const fetchData = async () => {
      // Simulating API calls
      const allRigsData: Rig[] = [
        {
          id: '1',
          title: 'Epic Gaming Rig',
          description: 'High-end gaming PC with RTX 3090',
          username: 'GamerPro99',
          likes: 150,
          components: [
            { name: 'CPU', price: 499.99, purchaseLink: '#' },
            { name: 'GPU', price: 1499.99, purchaseLink: '#' },
          ],
        },
        {
          id: '2',
          title: 'Budget Gaming Build',
          description: 'Affordable gaming PC with great performance',
          username: 'BudgetGamer',
          likes: 75,
          components: [
            { name: 'CPU', price: 199.99, purchaseLink: '#' },
            { name: 'GPU', price: 299.99, purchaseLink: '#' },
          ],
        },
        {
          id: '3',
          title: 'Compact Powerhouse',
          description: 'Small form factor PC with big performance',
          username: 'TinyTitan',
          likes: 120,
          components: [
            { name: 'CPU', price: 349.99, purchaseLink: '#' },
            { name: 'GPU', price: 699.99, purchaseLink: '#' },
          ],
        },
        {
          id: '4',
          title: 'Streaming Dream',
          description: 'Optimized for streaming and content creation',
          username: 'StreamQueen',
          likes: 200,
          components: [
            { name: 'CPU', price: 599.99, purchaseLink: '#' },
            { name: 'GPU', price: 899.99, purchaseLink: '#' },
          ],
        },
        {
          id: '5',
          title: 'Silent Assassin',
          description: 'Ultra-quiet PC for stealth gaming',
          username: 'NinjaGamer',
          likes: 90,
          components: [
            { name: 'CPU', price: 299.99, purchaseLink: '#' },
            { name: 'GPU', price: 599.99, purchaseLink: '#' },
          ],
        },
        {
          id: '6',
          title: 'RGB Extravaganza',
          description: 'Flashy build with all the colors',
          username: 'RainbowWarrior',
          likes: 180,
          components: [
            { name: 'CPU', price: 399.99, purchaseLink: '#' },
            { name: 'GPU', price: 799.99, purchaseLink: '#' },
          ],
        },
        {
          id: '7',
          title: 'Workstation Wonder',
          description: 'Professional-grade PC for 3D rendering and CAD',
          username: '3DModelMaster',
          likes: 110,
          components: [
            { name: 'CPU', price: 799.99, purchaseLink: '#' },
            { name: 'GPU', price: 1299.99, purchaseLink: '#' },
          ],
        },
        {
          id: '8',
          title: 'Retro Revival',
          description: 'Modern PC in a vintage case',
          username: 'OldSchoolCool',
          likes: 95,
          components: [
            { name: 'CPU', price: 249.99, purchaseLink: '#' },
            { name: 'GPU', price: 399.99, purchaseLink: '#' },
          ],
        },
        {
          id: '9',
          title: 'Liquid Dreams',
          description: 'Custom water-cooled beast',
          username: 'CoolMaster',
          likes: 220,
          components: [
            { name: 'CPU', price: 549.99, purchaseLink: '#' },
            { name: 'GPU', price: 1199.99, purchaseLink: '#' },
          ],
        },
        {
          id: '10',
          title: 'VR Virtuoso',
          description: 'Optimized for virtual reality experiences',
          username: 'VRExplorer',
          likes: 130,
          components: [
            { name: 'CPU', price: 449.99, purchaseLink: '#' },
            { name: 'GPU', price: 849.99, purchaseLink: '#' },
          ],
        },
        {
          id: '11',
          title: 'Eco Warrior',
          description: 'Energy-efficient green PC',
          username: 'GreenGamer',
          likes: 85,
          components: [
            { name: 'CPU', price: 229.99, purchaseLink: '#' },
            { name: 'GPU', price: 349.99, purchaseLink: '#' },
          ],
        },
        {
          id: '12',
          title: 'Overclocking Champion',
          description: 'Pushed to the limits for maximum performance',
          username: 'OCMaster',
          likes: 170,
          components: [
            { name: 'CPU', price: 649.99, purchaseLink: '#' },
            { name: 'GPU', price: 1099.99, purchaseLink: '#' },
          ],
        },
        {
          id: '13',
          title: 'Home Theater PC',
          description: 'Sleek and powerful media center',
          username: 'CouchGamer',
          likes: 100,
          components: [
            { name: 'CPU', price: 279.99, purchaseLink: '#' },
            { name: 'GPU', price: 449.99, purchaseLink: '#' },
          ],
        },
        {
          id: '14',
          title: 'Dual-System Behemoth',
          description: 'Two PCs in one case for streaming and gaming',
          username: 'TwoInOne',
          likes: 190,
          components: [
            { name: 'CPU1', price: 399.99, purchaseLink: '#' },
            { name: 'CPU2', price: 299.99, purchaseLink: '#' },
            { name: 'GPU', price: 999.99, purchaseLink: '#' },
          ],
        },
        {
          id: '15',
          title: 'Minimalist Marvel',
          description: 'Clean and simple high-performance build',
          username: 'LessIsMore',
          likes: 140,
          components: [
            { name: 'CPU', price: 379.99, purchaseLink: '#' },
            { name: 'GPU', price: 749.99, purchaseLink: '#' },
          ],
        },
        {
          id: '16',
          title: 'Portable Powerhouse',
          description: 'High-performance PC in a briefcase',
          username: 'RoadWarrior',
          likes: 160,
          components: [
            { name: 'CPU', price: 329.99, purchaseLink: '#' },
            { name: 'GPU', price: 649.99, purchaseLink: '#' },
          ],
        },
        {
          id: '17',
          title: 'Budget Content Creator',
          description: 'Affordable PC for video editing and streaming',
          username: 'CreatorOnABudget',
          likes: 110,
          components: [
            { name: 'CPU', price: 249.99, purchaseLink: '#' },
            { name: 'GPU', price: 399.99, purchaseLink: '#' },
          ],
        },
        {
          id: '18',
          title: 'Steampunk Dream',
          description: 'Victorian-inspired custom PC',
          username: 'SteamDreamer',
          likes: 200,
          components: [
            { name: 'CPU', price: 399.99, purchaseLink: '#' },
            { name: 'GPU', price: 799.99, purchaseLink: '#' },
          ],
        },
        {
          id: '19',
          title: 'Quantum Leap',
          description: 'Experimental PC with cutting-edge components',
          username: 'FutureTech',
          likes: 180,
          components: [
            { name: 'CPU', price: 999.99, purchaseLink: '#' },
            { name: 'GPU', price: 1599.99, purchaseLink: '#' },
          ],
        },
        {
          id: '20',
          title: 'Fanless Wonder',
          description: 'Completely silent PC with passive cooling',
          username: 'SilentNinja',
          likes: 130,
          components: [
            { name: 'CPU', price: 299.99, purchaseLink: '#' },
            { name: 'GPU', price: 549.99, purchaseLink: '#' },
          ],
        },
        {
          id: '21',
          title: 'AI Accelerator',
          description: 'Specialized build for machine learning tasks',
          username: 'AIWizard',
          likes: 150,
          components: [
            { name: 'CPU', price: 799.99, purchaseLink: '#' },
            { name: 'GPU', price: 2499.99, purchaseLink: '#' },
          ],
        },
        {
          id: '22',
          title: 'Threadripper Titan',
          description: 'Multi-threaded monster for heavy workloads',
          username: 'ThreadMaster',
          likes: 190,
          components: [
            { name: 'CPU', price: 1999.99, purchaseLink: '#' },
            { name: 'GPU', price: 1399.99, purchaseLink: '#' },
          ],
        },
      ]
      setAllRigs(allRigsData)

      if (isLoggedIn && currentUser) {
        const userRigsData = allRigsData.filter(
          (rig) => rig.username === currentUser.username
        )
        setUserRigs(userRigsData)

        // Simulating favorite rigs (you'd fetch this from an API in a real app)
        setFavoriteRigs(allRigsData.slice(0, 2))
      }
    }

    fetchData()
  }, [isLoggedIn, currentUser])

  const handleLogin = (username: string, password: string) => {
    // Implement actual login logic here
    setIsLoggedIn(true)
    setCurrentUser({
      id: '1',
      username,
      avatar: `https://api.dicebear.com/9.x/pixel-art/svg?seed=${username}`,
    })
  }

  const handleLike = (rigId: string) => {
    setAllRigs((prevRigs) =>
      prevRigs.map((rig) =>
        rig.id === rigId ? { ...rig, likes: rig.likes + 1 } : rig
      )
    )
  }

  const handleFavorite = (rigId: string) => {
    const rigToFavorite = allRigs.find((rig) => rig.id === rigId)
    if (rigToFavorite) {
      setFavoriteRigs((prevFavorites) => {
        // Check if the rig is already in favorites
        const isAlreadyFavorite = prevFavorites.some((fav) => fav.id === rigId)
        if (isAlreadyFavorite) {
          // If it's already a favorite, remove it
          return prevFavorites.filter((fav) => fav.id !== rigId)
        } else {
          // If it's not a favorite, add it
          return [...prevFavorites, rigToFavorite]
        }
      })
    }
  }

  const handleFollow = (username: string) => {
    // Implement follow logic here
    console.log(`Following ${username}`)
  }

  return (
    <Router>
      <div className="app-container">
        <Header
          isLoggedIn={isLoggedIn}
          userAvatar={currentUser?.avatar || ''}
        />
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Feed
                  userRigs={userRigs}
                  favoriteRigs={favoriteRigs}
                  allRigs={allRigs}
                  onLike={handleLike}
                  onFavorite={handleFavorite}
                />
              ) : (
                <LoginForm onLogin={handleLogin} />
              )
            }
          />
          <Route
            path="/build/:id"
            element={
              <BuildDetails
                builds={allRigs}
                isLoggedIn={isLoggedIn}
                userAvatar={currentUser?.avatar || ''}
              />
            }
          />
          <Route
            path="/user/:username"
            element={
              <UserProfile
                builds={allRigs}
                currentUser={currentUser?.username || ''}
                onFollow={handleFollow}
              />
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  )
}

export default MainApp
